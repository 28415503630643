<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :showFields="showFields"
      v-model="modelData"
      :updatable="false"
      :deletable="false"
      :customBtns="_titleBarCustomBtns"
      :customDropdownMenu="_titleCustomDropdownMenu"
      titleKey="no"
      @title-bar-custom-btn-click="$_titleBarCustomBtnClick($event)"
      @title-custom-dropdown-menu-click="$_titleBarCustomDropdownClick($event)"
    ></WsReadSection>
    <WsCrud
      v-if="modelData"
      class="mt-40"
      :title="$t('商品資訊')"
      :modelName="subModelName"
      :label="subLabel"
      :fields="subfields"
      :showFields="shopOrderShopProductShowFields"
      :infiniteScroll="false"
      :params="_params"
      :creatable="false"
      inRowBtnRead
      :inRowBtnUpdate="false"
      :inRowBtnDelete="false"
      :showExpand="false"
      :expandable="false"
      :hasFilter="false"
    ></WsCrud>
    <div v-if="modelData && modelData.shop_return_records.length">
      <WsCard class="mt-20">
        <!-- <WsReadSection
          v-for="(returnItem) in modelData.shop_return_records"
          :key="returnItem.id"
          :id="returnItem.id"
          title="退貨資訊"
          :modelName="returnModelName"
          :label="returnLabel"
          :fields="returnFields"
          :leftFields="returnLeftFields"
          :rightFields="[]"
          :updatable="false"
          :deletable="false"
          :showBreadcrumbs="false"
          titleKey="id"
        ></WsReadSection> -->
        <WsCrud
          v-if="modelData"
          class="mt-40"
          :title="$t('退貨資訊')"
          :modelName="returnModelName"
          :fields="returnFields"
          :showFields="returnShowFields"
          :titleBarCustomBtns="_returnTitleBarCustomBtns"
          :infiniteScroll="false"
          :params="_params"
          :creatable="false"
          inRowBtnRead
          :inRowBtnUpdate="false"
          :inRowBtnDelete="false"
          :showExpand="true"
          :expandable="true"
          :hasFilter="false"
          v-model="returnModelDatas"
          @title-bar-custom-btn-click="$_returnTitleBarCustomBtnClick($event)"
        ></WsCrud>
      </WsCard>
    </div>
    <WsPopupAlert
      ref="alertPopup"
      :title="alertPopup.title"
      :description="alertPopup.description"
      @submit="$_alertPopupSubmit($event)"
    >
    </WsPopupAlert>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_order";
import subModel from "@/__vue2stone_cms/models/shop_order_shop_product";
import returnModel from "@/__vue2stone_cms/models/shop_return_record";
export default {
  metaInfo() {
    return {
      title: "訂單列表",
    };
  },
  data: () => ({
    modelName: model.modelName,
    subModelName: subModel.modelName,
    returnModelName: returnModel.modelName,
    label: model.label,
    subLabel: subModel.label,
    returnLabel: returnModel.label,
    fields: model.fields,
    subfields: subModel.fields,
    returnFields: returnModel.fields,
    returnLeftFields: returnModel.leftFields,
    showFields: [
      "no",
      "orderer",
      "orderer_tel",
      "orderer_address",
      "type",
      "receiver",
      "receiver_tel",
      "receive_address",
      "created_at",
      "ship_time",
      "ship_status",
      "status",
      "pay_status",
      "ship_date",
      "ship_way",
      "invoice_number",
      "invoice_status",
      "invoice_type",
      "package_way",
      "invoice_title",
      "invoice_uniform_number",
      "invoice_carrier_type",
      "invoice_carrier_number",
      "receive_remark",
      "customer_service_remark",
      "receive_way",
      "products_price",
      "freight",
      "invite_no_deduct",
      "bonus_points",
      "bonus_points_deduct",
      "campaign_deduct",
      "order_price",
      "pay_type",
      "pay_at",
      "return_price",
      "orderer_email",
    ],
    // leftFields: [
    //   "no",
    //   "type",
    //   "created_at",
    //   "pay_status",
    //   "orderer",
    //   "receiver",
    //   "receiver_tel",
    //   "receive_address",
    //   "receive_way",
    //   "receive_remark",
    //   "package_way",
    //   "status",
    //   "ship_status",
    //   "ship_date",
    //   "ship_remark",
    //   "customer_service_remark",
    //   "ship_way",
    // ],
    // rightFields: [
    //   "pay_type",
    //   "pay_status",
    //   "freight",
    //   "products_price",
    //   "order_price",
    //   "invoice_number",
    //   "invoice_status",
    //   "invoice_type",
    //   "orderer_email",
    // ],
    modelData: null,
    returnModelDatas: null,
    returnShowFields: [
      "shop_order_shop_product__no",
      "shop_order_shop_product__name",
      "shop_order_shop_product__spec",
      "shop_order_shop_product__weight_capacity",
      "shop_order_shop_product__price",
      "count",
    ],
    shopOrderShopProductShowFields: [
      "name",
      "shop_product_no",
      "shop_product_weight_capacity",
      "shop_product_storage_space",
      "spec",
      "weight_capacity",
      "price",
      "original_count",
      "count",
    ],
    alertPopup: {},
  }),

  computed: {
    _params() {
      return {
        shop_order: this._id,
      };
    },
    _id() {
      return this.$route.params.id;
    },
    _titleBarCustomBtns() {
      return [
        {
          label: this.$t("匯出訂單"),
          event: "export-excel",
          icon: {
            left: true,
            name: "icon-ws-outline-cloud-download",
          },
        },
        {
          label: this.$t("列印揀貨單"),
          event: "print",
          icon: {
            left: true,
            name: "icon-md-print",
          },
        },
      ];
    },
    _returnTitleBarCustomBtns() {
      let _titleBarCustomBtns = [];
      _titleBarCustomBtns.push({
        label: this.$t("取消退訂"),
        event: "return-cancel",
      });
      _titleBarCustomBtns.push({
        label: this.$t("異動退訂"),
        event: "return-update",
      });
      return _titleBarCustomBtns;
    },
    _titleCustomDropdownMenu() {
      return [
        {
          id: "change_order_status",
          items: [
            {
              text: this.$t("成立"),
              value: "established",
            },
            {
              text: this.$t("未成立"),
              value: "not-established",
            },
            {
              text: this.$t("申請部分退訂"),
              value: "return-part-apply",
            },
            {
              text: this.$t("申請全部退訂"),
              value: "cancel",
            },
            {
              text: this.$t("部分退訂完成"),
              value: "return-part-complete",
            },
            {
              text: this.$t("全部退訂完成"),
              value: "cancel-complete",
            },
            {
              text: this.$t("訂單完成"),
              value: "complete",
            },
          ],
          placeholder: this.$t("更改訂單狀態"),
        },
        {
          id: "change_ship_status",
          items: [
            {
              text: this.$t("待出貨"),
              value: "unfulfilled",
            },
            {
              text: this.$t("準備出貨"),
              value: "collected",
            },
            {
              text: this.$t("已出貨"),
              value: "shipped",
            },
          ],
          placeholder: this.$t("更改出貨狀態"),
        },
      ];
    },
  },
  methods: {
    async $_titleBarCustomBtnClick($event) {
      if ($event == "export-excel") {
        this.$_onExportExcel();
      } else if ($event === "print") {
        this.$_exportPdf();
      }
    },
    async $_returnTitleBarCustomBtnClick($event) {
      if ($event == "return-cancel") {
        this.$_alertPopupOpen(this.returnModelDatas);
        this.alertPopup = {
          title: "",
          description: this.$t("是否取消退訂？"),
          id: $event,
        };
      } else if ($event === "return-update") {
        this.$router.push(`/part_return/${this._id}/update`);
      }
    },
    $_alertPopupClose() {
      this.$refs.alertPopup.close();
    },
    $_alertPopupOpen($event) {
      this.$refs.alertPopup.open($event);
    },
    async $_returnCancel() {
      let postArr = [];
      try {
        this.returnModelDatas.forEach((item) => {
          postArr.push(
            this.$axios.delete(`/${this.returnModelName}/${item.id}`)
          );
        });
        await Promise.all(postArr);
        this.returnModelDatas = [];
        this.modelData.shop_return_records = [];
      } catch (err) {
        alert(err);
      }
    },
    async $_onExportExcel() {
      //下載 excel
      const url = `/shop_order/export/excel/signedurl`;
      let params = {
        shop_orders: this._id,
      };
      try {
        const res = await this.$axios.get(url, { params });
        window.open(res.data, "_blank");
      } catch (err) {
        alert(err);
      }
    },
    async $_exportPdf() {
      //下載 pdf
      try {
        const url = "shop_order/export/pdf/signedurl";
        let params = {
          shop_orders: this._id,
        };
        const res = await this.$axios.get(url, { params });
        let createWindow = window.open(res.data);
        createWindow.print();
      } catch (err) {
        alert(err);
      }
    },
    async $_titleBarCustomDropdownClick($event) {
      if ($event.id == "change_order_status") {
        if (
          this.modelData.shop_return_records.length &&
          ($event.value === "return-part-apply" || $event.value === "cancel")
        ) {
          return;
        }
        try {
          const url = `/${this.modelName}/${this._id}`;
          let data = {
            status: $event.value,
          };
          const res = await this.$axios.patch(url, data);
          this.modelData = res.data.data;
          if ($event.value === "return-part-apply") {
            this.$router.push(`/part_return/${this.$route.params.id}`);
          } else if ($event.value === "cancel") {
            this.$router.push({
              path: `/all_return`,
              query: { id: this.$route.params.id.toString() },
            });
          }
        } catch (err) {
          alert(err);
        }
      } else if ($event.id === "change_ship_status") {
        if (this.modelData.ship_status == $event.value) return;
        let _modelData = { ...this.modelData };
        _modelData.ship_status = $event.value;
        if ($event.value === "shipped") {
          _modelData.ship_date = this.$moment().format("YYYY-MM-DD");
          if (
            _modelData.status === "established" &&
            this.modelData.ship_status === "collected"
          ) {
            _modelData.status = "complete";
          }
        }
        this.$_updateData(_modelData);
      }
    },
    async $_updateData(data) {
      try {
        const res = await this.$axios.patch(
          `/${this.modelName}/${this._id}`,
          data
        );
        this.modelData = res.data.data;
      } catch (err) {
        alert(err);
      }
    },
    $_alertPopupSubmit() {
      if (this.alertPopup.id === "return-cancel") {
        this.$_alertPopupClose();
        this.$_returnCancel();
      }
    },
  },
};
</script>

<style>
</style>